<template>
  <div class="healthIQ">
    <el-card>
      <el-row>
        <el-col :span="2">
          <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
        </el-col>
        <el-col :span="15" style="display: flex">
          <div class="">
            <el-input placeholder="请输入题目名称" size="medium" v-model="params.keyWord" clearable @clear="getUserList">
              <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
            </el-input>
            <el-cascader size="medium" collapse-tags v-model="projectId" :options="dictList" :props="props" @change="handleChange" clearable></el-cascader>
          </div>
        </el-col>
        <el-col :span="7">
          <div style="display: flex">
            <el-button type="primary" size="medium" class="demo-input-label" @click="topicClick">编辑选题</el-button>
            <!-- <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-download" @click="sassOrderHealthExcelExportFile">导出</el-button> -->
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div class="main">
      <el-table :data="records" :height="innerHeight" border style="width: 98%" v-loading="loading">
        <el-table-column type="index" width="100" align="center"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="questionNo" label="编号" width="260"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="question" label="题目" width="260"></el-table-column>
        <el-table-column prop="question" label="是否正确" width="180">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.answerFlag == '1'">正确</el-tag>
            <el-tag type="warning" v-else>错误</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="answer" label="答案" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="ymd" label="时间">
          <template slot-scope="scope">
            <el-tag v-if="!scope.row.isTrue">{{ scope.row.ymd || '00:00:00' }}</el-tag>
            <el-date-picker style="width: 100%" v-else v-model="scope.row.ymd" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <div style="display: flex">
              <el-button type="primary" v-if="!scope.row.isTrue" icon="el-icon-edit" size="mini" @click="scope.row.isTrue = true">编辑</el-button>
              <el-button type="primary" v-else icon="el-icon-edit" size="mini" @click="updateClick(scope.row)">保存</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import pagination from '@/components/common/pagination/Index.vue'
import draggable from 'vuedraggable'
export default {
  name: 'AdminDemoIndex',
  components: {
    pagination,
    draggable,
  },
  data() {
    return {
      isSequence: false,
      props: {
        label: 'name',
        value: 'id',
        multiple: true,
        emitPath: true,
      },
      params: { pageNum: 1, pageSize: 10, keyWord: '' },
      records: [],
      total: 0,
      isIndeterminate: false,
      saveHealthIQ: {
        answer: '',
        answerFlag: 0,
        question: '',
        dictIds: [],
      },
      tree: [15014],
      addDialog: true,
      // 控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      cascaderDictList: [],

      defaultChecked: [],
      projectId: [],
      dictList: [],
      endTime: '',
      startTime: '',
      updateYMDparams: {},
      loading: false,
      hiqNum: 0,
      activityId: '',
    }
  },

  mounted() {
    const { id, tenantName, status, startTime, endTime, hiqNum } = JSON.parse(window.localStorage.getItem('Model'))

    this.orderStatus = status
    this.params.modelId = id
    this.updateYMDparams.modelId = id

    this.updateYMDparams.tenantName = tenantName
    this.params.tenantName = tenantName
    this.startTime = startTime
    this.endTime = endTime
    this.hiqNum = hiqNum
    this.getData()
    this.getTagsList()
  },
  methods: {
    updateClick(ITEM) {
      const records = this.records
      this.updateInfo(ITEM)
      records.map((item) => {
        if (item.id == ITEM.id) {
          item.isTrue = false
        }
        return item
      })
      this.records = records
    },
    async updateInfo(ITEM) {
      const { ymd, id } = ITEM

      this.updateYMDparams.ymd = ymd
      this.updateYMDparams.id = id
      const res = await this.$API.modelHiq.updateYMD(this.updateYMDparams)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success('更新时间成功')
    },

    // 放置
    drop(e, index) {
      console.log(e, index)
    },

    //标签刷选
    handleChange(val) {
      this.params.tags = val.map((item) => item[item.length - 1])
      this.getData()
    },
    topicClick() {
      this.$router.push(`/xuanTiManage/xuanTi?id=${this.params.modelId}&tenantName=${this.params.tenantName}&hiqNum=${this.hiqNum}`)
    },
    async getTagsList() {
      const res = await this.$API.dict.get({ dictType: 1 })
      if (res.status !== 200) return ''
      this.dictList = res.data
    },

    async sassOrderHealthExcelExportFile() {
      const { keyWord, orderId, companyNo, tags } = this.params
      const res = await this.$API.sassOrderHealthExcelExportFile({
        keyWord,
        orderId,
        companyNo,
        tags,
      })
      const link = document.createElement('a')
      let blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.download = 'healthIQ.xlsx'
      document.body.appendChild(link)
      link.click()
    },

    searchClick() {
      this.params.pageNum = 1
      this.getData()
    },
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.getData()
    },
    handleCurrentChange(newPage) {
      this.params.pageNum = newPage
      this.getData()
    },
    async getData() {
      this.loading = true
      const res = await this.$API.modelHiq.hiqSelectedInit(this.params)
      this.loading = false
      if (res.status == 200) {
        const { records, total } = res.data

        records.map((item) => {
          item['isTrue'] = false
        })
        this.records = records
        this.total = +total
        console.log(this.total)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}
.draggable {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.draggable_item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
// .el-cascader {
//   width: 100%;
// }
.tabbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

// .el-breadcrumb {
//   margin-bottom: 20px;
// }
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}

.el-tag {
  margin: 7px;
}
.el-input {
  width: 300px;
  margin-right: 10px;
}
</style>
